@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/mixins';
.vjs-paused.vjs-has-started {
  .vjs-big-play-button {
    display: none;
  }
}

.customLayer {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: pointer;
}

.customLayer.pause {
  background: rgba(255, 255, 255, 0.5);
}

.onClick {
  line-height: 20px;
  color: white;
  font-weight: bold;
  font-size: 2em;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.onClick.pause {
  opacity: 0;
  transition: 0.2s;
}

.onClick.pause:hover {
  opacity: 0.8;
}

.vjs-big-play-button {
  width: 70px !important;
  height: 70px !important;
  background: url('./img/btn-play.png')no-repeat !important;
  background-size: 100% !important;
  border: none !important;
  outline: none;
  .vjs-icon-placeholder {
      &::before {
      display: none !important;
    }
  }
  top:50% !important;
  left: 52% !important;
  @include media-breakpoint-down(sm){
    top:45% !important;
    left: 53% !important;
  }
}

.videoWrapper {
  max-width: 100%;
  @include media-breakpoint-up(lg) {
    width: 700px;
  }
}